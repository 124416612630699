body, html {
  height: 100%;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
/*
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
*/

html, body {
  font-family: var(--main-font), sans-serif;
  font-weight: var(--main-font-weight);
}

h1, h2, h3, h4 {
  font-family: var(--main-font), sans-serif;
  font-weight: normal;
}

#root {
  position: relative;
  min-width: 320px;
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
  overflow-x: hidden;
}

#root > div {
  height: 100%;
}

@media (hover: none) {
  .MuiButton-containedPrimary:hover {
    background-color: var(--main-color) !important;
  }
}

.MuiButton-containedPrimary {
    background-color: var(--main-color) !important;
}

@media (min-width: 600px) {
  .MuiToolbar-regular {
      min-height: 56px !important;
  }
}

@media (min-width: 0px) and (orientation: landscape) {
  .MuiToolbar-regular {
       min-height: 56px !important;
  }
}

.MuiPickersToolbar-toolbar {
  background-color:  var(--main-color) !important;
}

.MuiPickersDay-daySelected {
  background-color:  var(--main-color) !important;
}

.MuiButton-textPrimary {
  color: var(--main-color) !important;
}


.ptr__children {
  overflow: auto !important;
}
